<template>
  <div class="vol-page">
    <div class="vol-linear"></div>
    <div class="container">
      <div class="vol-banner">
        <span class="vol-event-name">志愿者注册</span>
        <img src="../../assets/volunteer/hearts.png"
             class="vol-hearts" />

        <img src="../../assets/volunteer/person.png"
             class="vol-person" />
      </div>
      <div class="vol-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active"
                aria-current="page">
              志愿者注册
            </li>
          </ol>
        </nav>
        <form class="row g-3"
              @submit.prevent="submit">
          <div class="col-md-6">
            <label class="form-label"><span>*</span>真实姓名</label>
            <input disabled
                   :value="user.real_name"
                   type="text"
                   class="form-control"
                   placeholder="请输入真实姓名"
                   required />
            <div class="valid-feedback">Looks good!</div>
            <div class="invalid-feedback">Please select a valid state.</div>
          </div>
          <div class="col-md-6">
            <label class="form-label"><span>*</span>手机号码</label>
            <input type="number"
                   :value="user.mobile"
                   name="mobile"
                   disabled
                   class="form-control"
                   placeholder="请输入手机号码（如绑定微信请输入绑定微信的手机号码）" />
          </div>
          <div class="col-md-6">
            <label class="form-label"><span>*</span>性别</label>
            <select name="gender"
                    class="form-select">
              <option value="0"
                      selected>请选择</option>
              <option value="1">男</option>
              <option value="2">女</option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="form-label"><span>*</span>身份</label>
            <select disabled
                    name="position"
                    class="form-select">
              <option selected>{{user.position}}</option>
            </select>
          </div>
          <div class="col-md-12">
            <label class="form-label">所在单位</label>
            <input type="text"
                   :value="user.company"
                   name="company"
                   disabled
                   class="form-control"
                   placeholder="请规范填写全称" />
          </div>
          <div class="col-md-4">
            <label class="form-label"><span>*</span>所属区域</label>
            <select disabled
                    id="inputState"
                    class="form-select">
              <option selected>{{user.city}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <label class="form-label"><span>&nbsp;</span></label>
            <select disabled
                    id="inputState"
                    class="form-select">
              <option selected>{{user.district}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <label class="form-label"><span>&nbsp;</span></label>
            <select disabled
                    id="inputState"
                    class="form-select">
              <option selected>{{user.street}}</option>
            </select>
          </div>
          <div class="col-md-12">
            <label class="form-label"> <span>*</span>服务类别（可多选）</label>
          </div>
          <div class="col-12">
            <div class="form-check form-check-inline"
                 v-for="(item, index) in serviceList"
                 :key="item">
              <input class="form-check-input"
                     type="checkbox"
                     :id="'inlineCheckbox' + index"
                     name="industry"
                     :value="item" />
              <label class="form-check-label"
                     :for="'inlineCheckbox'+index">{{item}}</label>
            </div>
          </div>
          <div class="col-12">
            <button type="submit"
                    class="btn bottom-btn">提交</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { getBaseData, userDetail, colunteerApply } from '@/network/API.js';
export default {
  data() {
    return {
      user: {},
      serviceList: [],
      genderAry: ["男", "女"],
      gender: -1,
    }
  },
  async beforeCreate() {
    this.user = await userDetail()
    if (this.user.is_volunteer == "1") {
      this.$toast("您已是志愿者")
      // setTimeout(() => {
      //   this.$router.go(-1)
      // }, 1500);

      return;
    }
  },
  async created() {
    this.fetchService()
  },
  methods: {
    async fetchService() {
      let res = await getBaseData("service_type")
      this.serviceList = res
    },
    async submit(event) {
      if (this.user.is_volunteer == 1) {
        this.$toast("您已是志愿者")
        return;
      }
      const data = new FormData(event.target)
      var params = {}
      for (let [a, b] of data) {
        params[a] = b
      }
      params.industry = data.getAll('industry').join()
      params = {
        ...params,
        real_name: this.user.real_name,
        mobile: this.user.mobile,
        position: this.user.position,
        company: this.user.company,
        city: this.user.city,
        city_code: this.user.city_code,
        district: this.user.district,
        district_code: this.user.district_code,
        street: this.user.street,
        street_code: this.user.region,
        province: "河北省",
        province_code: "130000",
        type: "personal"
      }
      if (params.gender == "0") {
        this.$toast("请选择性别")
        return;
      }
      if (params.industry == "") {
        this.$toast("请选择服务类别")
        return;
      }
      await colunteerApply({
        ...params
      })
      this.$toast("志愿者注册成功")
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    }
  }
}
</script>
<style>

.vol-hearts {
  top: -80px;
}

.vol-person {
  position: absolute;
  z-index: 3;
  right: -10px;
  top: -10px;
  width: 326px;
  height: 359px;
}

.bottom-btn {
  width: 260px;
  margin: 30px auto;
  position: relative;
  z-index: 4;
  background: linear-gradient(97deg, #ffb990 0%, #ff3241 100%);
  border: none;
  color: #fff !important;
}
.form-label span {
  color: red;
}
</style>
